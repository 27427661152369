<template>
  <CourseConversionBanner v-bind="$props" />
</template>
<script>
import CourseConversionBanner from '~/components/ctas/CourseConversionBanner/CourseConversionBanner'
export default {
  components: {
    CourseConversionBanner
  },
  props: {
    heading: {
      type: String,
      default: null
    },
    course: {
      type: String,
      default: null
    },
    boxPositionRight: {
      type: Boolean,
      default: false
    },
    image: {
      type: Array,
      default: () => []
    },
    cta: {
      type: Object,
      default: null
    },
    staticPrice: {
      type: Number,
      default: null
    },
    staticCompareAtPrice: {
      type: Number,
      default: null
    }
  }
}
</script>
